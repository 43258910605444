import { Lock } from '@mui/icons-material';
import {
	Alert,
	Box,
	Button,
	Card,
	CardContent,
	MenuItem,
	Select,
	SelectChangeEvent,
	Skeleton,
	Typography,
	styled,
} from '@mui/material';
import { Breadcrumbs, PageContainer } from 'core/components';
import { useAPI } from 'core/hooks';
import { useFormik } from 'formik';
import { Permission, PermissionGroups } from 'modules/admin/modules/users/api';
import UserService from 'modules/admin/modules/users/api/userService';
import PermissionSection from 'modules/admin/modules/users/components/PermissionSection';
import AccountRole from 'modules/admin/shared/accountRoles';
import { Item } from 'modules/iteris-ui/shared/types/Item';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import UserPaths from './paths';

const Label = styled('label')({ whiteSpace: 'nowrap', fontWeight: 600 });
const InputContainer = styled(Box)(({ theme }) => ({
	display: 'flex',
	columnGap: theme.spacing(2),
	alignItems: 'center',
	height: 40,
}));

const FooterRow = styled(Box)(({ theme }) => ({
	marginTop: theme.spacing(2),
	display: 'flex',
	columnGap: theme.spacing(2),
}));

interface ManagePermissionsLegacyForm {
	permissions: { id: number; fullAccess: boolean; viewOnly: boolean }[];
}

export default function ManagePermissionsLegacy() {
	const { t } = useTranslation('admin/users');

	const userService = useAPI(UserService);

	const navigate = useNavigate();
	const navigateToUserAccounts = () => navigate(UserPaths.buildPath({}));

	const [loading, setLoading] = useState<boolean>(true);
	const [saving, setSaving] = useState<boolean>(false);

	const [accountRoles, setAccountRoles] = useState<Item<string, string>[]>([]);
	const [accountRole, setAccountRole] = useState<AccountRole>(AccountRole.JurisdictionAdmin);

	const [permissions, setPermissions] = useState<PermissionGroups>();

	useEffect(() => {
		const load = async () => {
			await Promise.all([
				userService
					.getPermissionGroups(AccountRole.JurisdictionAdmin)
					.then((permissionGroups) => setPermissions(permissionGroups)),
				userService
					.getRoles()
					.then((roles) => setAccountRoles(roles.map((role) => ({ label: role.name, value: role.code })))),
			]);
		};

		load().then(() => setLoading(false));
	}, [userService]);

	const { values, setFieldValue, handleSubmit } = useFormik<ManagePermissionsLegacyForm>({
		initialValues: { permissions: [] },
		onSubmit: async (managePermissionsForm: ManagePermissionsLegacyForm) => {
			setSaving(true);
			await userService.updatePermissions(accountRole, managePermissionsForm.permissions);
			setSaving(false);
			navigateToUserAccounts();
		},
	});

	const handleAccountRoleChanged = (e: SelectChangeEvent<AccountRole>) => {
		setLoading(true);

		const updatedRole = e.target.value as AccountRole;

		setAccountRole(updatedRole);
		userService
			.getPermissionGroups(updatedRole)
			.then((groups) => setPermissions(groups))
			.finally(() => setLoading(false));
	};

	const handlePermissionChanged = ({ id, viewOnly, fullAccess }: Permission) => {
		const updatedPermission = { id, viewOnly, fullAccess };
		const existingPermission = values.permissions.find((permission) => permission.id === id);

		if (existingPermission) {
			setFieldValue(
				'permissions',
				values.permissions.map((permission) => (permission.id === id ? updatedPermission : permission)),
			);
		} else {
			setFieldValue('permissions', [...values.permissions, updatedPermission]);
		}
	};

	return (
		<PageContainer>
			<Breadcrumbs ns="admin/users" variant="h5" />

			<Box display="flex" flexDirection="row" justifyContent="space-between">
				<Typography variant="h3" gutterBottom>
					{t('permissions.title')}
				</Typography>
				<Button variant="text" startIcon={<Lock />} onClick={() => navigate(UserPaths.Permissions.buildPath({}))}>
					{t('permissions.legacy.manage_new')}
				</Button>
			</Box>
			<Typography paragraph>{t('permissions.subtitle')}</Typography>

			<Alert severity="warning" sx={{ mb: 2 }}>
				{t('permissions.legacy.info')}
			</Alert>

			<form noValidate onSubmit={handleSubmit}>
				<Card>
					<CardContent>
						{!loading ? (
							<>
								<Box display="flex" mb={4}>
									<InputContainer>
										<Label>{t('roles.title')}:</Label>
										<Select name="accountRole" value={accountRole} onChange={handleAccountRoleChanged} size="small">
											{accountRoles.map((role) => (
												<MenuItem key={role.value} value={role.value}>
													{role.label}
												</MenuItem>
											))}
										</Select>
									</InputContainer>
								</Box>

								{permissions?.permissionGroups.map((group) => (
									<PermissionSection
										key={group.name}
										title={group.name}
										permissions={group.permissions}
										onPermissionChanged={handlePermissionChanged}
									/>
								))}
							</>
						) : (
							<Box height={800} width="100%">
								<Skeleton sx={{ WebkitTransform: 'none' }} width="100%" height="100%" />
							</Box>
						)}
					</CardContent>
				</Card>

				<FooterRow>
					<Button variant="outlined" onClick={navigateToUserAccounts}>
						{t('buttons.cancel', { ns: 'core' })}
					</Button>
					<Button variant="contained" type="submit" disabled={saving}>
						{t('buttons.save', { ns: 'core' })}
					</Button>
				</FooterRow>
			</form>
		</PageContainer>
	);
}
