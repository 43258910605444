import { CanAccess } from 'core/components';
import NoPermission from 'core/components/NoPermission';
import Redirect from 'core/components/Redirect';
import Title from 'core/components/Title';
import { Actions } from 'core/types/permissions';
import { Route } from 'core/types/routing';
import TransmittalStepRedirector from 'modules/transmittals/components/TransmittalStepRedirector';
import TransmittalStepsContainer from 'modules/transmittals/components/TransmittalStepsContainer';
import { useTransmittal } from 'modules/transmittals/providers/TransmittalProvider';
import { lazy, PropsWithChildren, useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import Permissions from 'types/Permissions';
import { programName } from 'types/Program';
import TransmittalPaths from './paths';
import TransmittalsStart from './TransmittalsStart';

const TransmittalsIndex = lazy(() => import('./TransmittalsIndex'));

const TransmittalStepStart = lazy(() => import('modules/transmittals/routes/TransmittalStepStart'));
const TransmittalStepConfirm = lazy(() => import('modules/transmittals/routes/TransmittalStepConfirm'));
const TransmittalStepTest = lazy(() => import('modules/transmittals/routes/TransmittalStepTest'));
const TransmittalStepProd = lazy(() => import('modules/transmittals/routes/TransmittalStepProd'));
const TransmittalStepComplete = lazy(() => import('modules/transmittals/routes/TransmittalStepComplete'));

function TransmittalStepStartWithId() {
	const { transmittal, reload } = useTransmittal();
	return (
		<TransmittalStepStart
			transmittal={transmittal}
			onGenerate={() => {
				reload();
			}}
		/>
	);
}

// Route component mappings
const TransmittalRoutes = [
	{
		path: TransmittalPaths.path,
		element: (
			<CanAccess resource={Permissions.Transmittals.resource} action={Actions.READ} denied={<NoPermission />}>
				<Title
					title={(t) => `${t('title', { ns: 'transmittals' })} - ${t('title', { ns: 'core' })}`}
					ns={['transmittals']}
				/>
				<Outlet />
			</CanAccess>
		),
		handle: {
			crumb: ({ t }) => t('title', { ns: 'transmittals' }),
		},
		children: [
			{
				path: TransmittalPaths.path,
				element: <TransmittalsIndex />,
				index: true,
			},
			{
				path: TransmittalPaths.Program.path,
				element: (
					<ErrorBoundary fallback={<Redirect to={TransmittalPaths.buildPath({})} />}>
						<Outlet />
					</ErrorBoundary>
				),
				handle: {
					crumb: ({ params }) => params.program.toUpperCase(),
				},
				children: [
					// Index
					{
						index: true,
						path: TransmittalPaths.Program.path,
						element: <TransmittalsStart />,
					},

					// View/Edit
					{
						path: TransmittalPaths.Program.Edit.path,
						element: <TransmittalStepsContainer />,
						children: [
							// Index, redirect to transmittal step
							{
								index: true,
								element: <TransmittalStepRedirector />,
							},

							// Start
							{
								path: TransmittalPaths.Program.Edit.Start.path,
								element: (
									<TransmittalProgramGuard>
										<TransmittalStepStartWithId />
									</TransmittalProgramGuard>
								),
							},

							// Confirm
							{
								path: TransmittalPaths.Program.Edit.Confirm.path,
								element: (
									<TransmittalProgramGuard>
										<TransmittalStepConfirm />
									</TransmittalProgramGuard>
								),
							},

							// Test
							{
								path: TransmittalPaths.Program.Edit.Test.path,
								element: (
									<TransmittalProgramGuard>
										<TransmittalStepTest />
									</TransmittalProgramGuard>
								),
							},

							// Production
							{
								path: TransmittalPaths.Program.Edit.Production.path,
								element: (
									<TransmittalProgramGuard>
										<TransmittalStepProd />
									</TransmittalProgramGuard>
								),
							},

							// Complete
							{
								path: TransmittalPaths.Program.Edit.Complete.path,
								element: <TransmittalStepComplete />,
							} as Route<typeof TransmittalPaths.Program.Edit>,
						],
					} as Route<typeof TransmittalPaths.Program.Edit>,
				],
			} as Route<typeof TransmittalPaths.Program>,
		],
	} as Route<typeof TransmittalPaths>,
];

export default TransmittalRoutes;

// Transmittal program guard ensures we're in the correct program for the transmittal
function TransmittalProgramGuard({ children }: PropsWithChildren) {
	const context = useTransmittal();
	const location = useLocation();
	const navigate = useNavigate();

	// Make sure we're in the correct program
	useEffect(() => {
		if (!context || !context.transmittal) return;
		const { transmittal } = context;

		// Get expected path for the program
		const expected = TransmittalPaths.Program.Edit.buildPath({
			program: programName(transmittal.program),
			id: transmittal.id,
		});

		// Expected path does not match actual path, redirect
		if (!location.pathname.startsWith(expected)) {
			navigate(expected, {
				replace: true,
			});
		}
	}, [location.pathname, navigate, context]);

	// eslint-disable-next-line react/jsx-no-useless-fragment
	return <>{children}</>;
}
