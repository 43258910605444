import { CanAccess } from 'core/components';
import NoPermission from 'core/components/NoPermission';
import Redirect from 'core/components/Redirect';
import { Route } from 'core/types/routing';
import SupplementPageContainer from 'modules/irp/modules/supplements/components/SupplementPageContainer';
import TransferVehicleSteps from 'modules/irp/modules/supplements/modules/transfer_vehicle/components/TransferSteps';
import TransferVehicleContainer from 'modules/irp/modules/supplements/modules/transfer_vehicle/components/TransferVehicleContainer';
import SupplementBlocker from 'modules/irp/modules/supplements/providers/SupplementProvider';
import { VehicleProvider } from 'modules/irp/modules/vehicles/providers/VehicleProvider';
import { VehicleIncludeFields, VehicleNew } from 'modules/irp/modules/vehicles/types/Vehicle';
import { PropsWithChildren, lazy, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import { useTypedParams } from 'react-router-typesafe-routes/dom';
import Permissions from 'types/Permissions';
import TransferVehiclePaths from './paths';
import TransferDetailsStep from './TransferDetailsStep';

const TransferDocumentsStep = lazy(() => import(`./TransferDocumentsStep`));
const TransferSubmitStep = lazy(() => import(`./TransferSubmitStep`));
const TransferVerifyStep = lazy(() => import(`./TransferVerifyStep`));

const TransferVehicleDelete = lazy(() => import(`./TransferVehicleDelete`));
const TransferAddVehicleOptionsRoute = lazy(() => import(`./TransferAddVehicleOptions`));
const TransferAddVehicleInfoRoute = lazy(() => import(`./TransferAddVehicleInfo`));
const TransferAddVehicleWeightGroupRoute = lazy(() => import(`./TransferAddVehicleWeightGroup`));
const TransferAddVehicleRegistrationRoute = lazy(() => import(`./TransferAddVehicleRegistration`));

// View Vehicle Detail
const VehicleDetailsRoute = lazy(() => import('./VehicleDetailsRoute'));
const VehicleDetailsPlate = lazy(() => import('modules/irp/modules/vehicles/components/VehicleDetailsPlate'));
const VehicleDetailsCredentials = lazy(
	() => import('modules/irp/modules/vehicles/components/VehicleDetailsCredentials'),
);
const VehicleDetailsPurchase = lazy(() => import('modules/irp/modules/vehicles/components/VehicleDetailsPurchase'));
const VehicleDetailsDocuments = lazy(() => import('modules/irp/modules/vehicles/components/VehicleDetailsDocuments'));
const VehicleDetailsRegistration = lazy(
	() => import('modules/irp/modules/vehicles/components/VehicleDetailsRegistration'),
);

function TransferVehicleIndex() {
	const { supplementKey } = useTypedParams(TransferVehiclePaths);
	return <Redirect to={TransferVehiclePaths.Details.buildPath({ supplementKey })} replace />;
}

function TransferVehicleDeleteContainer({ children }: PropsWithChildren) {
	const { deleteVehicleKey } = useTypedParams(TransferVehiclePaths.Vehicle);
	return <VehicleProvider vehicleKey={deleteVehicleKey}>{children}</VehicleProvider>;
}

function TransferVehicleDeleteIndex() {
	const { supplementKey, deleteVehicleKey, addVehicleKey } = useTypedParams(TransferVehiclePaths.Vehicle.Delete);
	return (
		<Redirect
			to={TransferVehiclePaths.Vehicle.Delete.buildPath({ supplementKey, deleteVehicleKey, addVehicleKey })}
			replace
		/>
	);
}

function TransferVehicleAddIndex() {
	const { supplementKey, addVehicleKey, deleteVehicleKey } = useTypedParams(TransferVehiclePaths.Vehicle.Add);
	const path =
		addVehicleKey === VehicleNew ? TransferVehiclePaths.Vehicle.Add.Options : TransferVehiclePaths.Vehicle.Add.Info;
	return <Redirect to={path.buildPath({ supplementKey, addVehicleKey, deleteVehicleKey })} />;
}

function TransferVehicleAddContainer({ children }: PropsWithChildren) {
	const { addVehicleKey } = useTypedParams(TransferVehiclePaths.Vehicle.Add);
	const includes: VehicleIncludeFields = useMemo(() => ({ weightGroup: true }), []);

	return (
		<VehicleProvider vehicleKey={addVehicleKey} includes={includes}>
			{children}
		</VehicleProvider>
	);
}

function WithBlocker({ children }: PropsWithChildren) {
	const { t } = useTranslation('irp/supplements/transfer_vehicle');
	return (
		<SupplementBlocker
			basePath={TransferVehiclePaths}
			title={t('dialogs.close.title', { ns: 'irp/supplements/transfer_vehicle' })}
		>
			{children}
		</SupplementBlocker>
	);
}

const TransferVehicleRoutes = [
	{
		path: TransferVehiclePaths.path,
		element: (
			<CanAccess
				resource={Permissions.IRP.Vehicles.resource}
				action={Permissions.IRP.Vehicles.actions.transfer}
				denied={<NoPermission />}
			>
				<SupplementPageContainer
					breadcrumbs={[
						'irp/supplements/transfer_vehicle',
						'irp/supplements/add_vehicle',
						'irp/supplements',
						'irp/vehicles',
					]}
					stepRoutes={[
						TransferVehiclePaths.Details.path,
						TransferVehiclePaths.Verify.path,
						TransferVehiclePaths.Submit.path,
					]}
					steps={<TransferVehicleSteps />}
				>
					<Outlet />
				</SupplementPageContainer>
			</CanAccess>
		),
		handle: {
			crumb: ({ t }) => t('title', { ns: 'irp/supplements/transfer_vehicle' }),
		},
		children: [
			// Index route
			{
				path: TransferVehiclePaths.path,
				index: true,
				element: <TransferVehicleIndex />,
			},

			// Steps
			{
				path: TransferVehiclePaths.Details.path,
				element: (
					<WithBlocker>
						<TransferDetailsStep />
					</WithBlocker>
				),
			},
			{
				path: TransferVehiclePaths.Documentation.path,
				element: (
					<WithBlocker>
						<TransferDocumentsStep />
					</WithBlocker>
				),
			},
			{
				path: TransferVehiclePaths.Verify.path,
				element: (
					<WithBlocker>
						<TransferVerifyStep />
					</WithBlocker>
				),
			},
			{
				path: TransferVehiclePaths.Submit.path,
				element: (
					<WithBlocker>
						<TransferSubmitStep />
					</WithBlocker>
				),
			},

			// Vehicle add/edit
			{
				path: TransferVehiclePaths.Vehicle.path,
				element: (
					<TransferVehicleContainer>
						<Outlet />
					</TransferVehicleContainer>
				),
				children: [
					// Index route
					{
						path: TransferVehiclePaths.Vehicle.path,
						index: true,
						element: <TransferVehicleDeleteIndex />,
					},

					// Vehicle delete
					{
						path: TransferVehiclePaths.Vehicle.Delete.path,
						handle: {
							crumb: ({ t }) => t('vehicle.delete.title', { ns: 'irp/supplements/transfer_vehicle' }),
						},
						element: (
							<TransferVehicleDeleteContainer>
								<TransferVehicleDelete />
							</TransferVehicleDeleteContainer>
						),
					} as Route<typeof TransferVehiclePaths.Vehicle.Delete>,

					// Vehicle View
					{
						path: TransferVehiclePaths.Vehicle.View.path,
						handle: {
							crumb: ({ t }) => t('details.title', { ns: 'irp/vehicles' }),
						},
						element: <VehicleDetailsRoute />,
						children: [
							{
								path: TransferVehiclePaths.Vehicle.View.path,
								index: true,
								element: <VehicleDetailsPlate />,
							},
							{
								path: TransferVehiclePaths.Vehicle.View.Credentials.path,
								element: <VehicleDetailsCredentials />,
							},
							{
								path: TransferVehiclePaths.Vehicle.View.Purchase.path,
								element: <VehicleDetailsPurchase />,
							},
							{
								path: TransferVehiclePaths.Vehicle.View.Documents.path,
								element: <VehicleDetailsDocuments />,
							},
							{
								path: TransferVehiclePaths.Vehicle.View.Registration.path,
								element: <VehicleDetailsRegistration />,
							} as Route<typeof TransferVehiclePaths.Vehicle.View.Registration>,
						],
					} as Route<typeof TransferVehiclePaths.Vehicle.View>,

					// Vehicle add
					{
						path: TransferVehiclePaths.Vehicle.Add.path,
						element: (
							<TransferVehicleAddContainer>
								<Outlet />
							</TransferVehicleAddContainer>
						),
						children: [
							// Index route
							{
								path: TransferVehiclePaths.Vehicle.Add.path,
								index: true,
								element: <TransferVehicleAddIndex />,
							},
							{
								path: TransferVehiclePaths.Vehicle.Add.Options.path,
								handle: {
									crumb: ({ t }) => t('vehicle.title', { ns: 'irp/supplements/add_vehicle' }),
								},
								element: <TransferAddVehicleOptionsRoute />,
							},
							{
								path: TransferVehiclePaths.Vehicle.Add.Info.path,
								handle: {
									crumb: ({ t }) => t('vehicle.title', { ns: 'irp/supplements/add_vehicle' }),
								},
								element: <TransferAddVehicleInfoRoute />,
							},
							{
								path: TransferVehiclePaths.Vehicle.Add.WeightGroup.path,
								handle: {
									crumb: ({ t }) => t('vehicle.title', { ns: 'irp/supplements/add_vehicle' }),
								},
								element: <TransferAddVehicleWeightGroupRoute />,
							},
							{
								path: TransferVehiclePaths.Vehicle.Add.Registration.path,
								handle: {
									crumb: ({ t }) => t('vehicle.title', { ns: 'irp/supplements/add_vehicle' }),
								},
								element: <TransferAddVehicleRegistrationRoute />,
							} as Route<typeof TransferVehiclePaths.Vehicle.Add.Registration>,
						],
					} as Route<typeof TransferVehiclePaths.Vehicle.Add>,
				],
			} as Route<typeof TransferVehiclePaths.Vehicle>,
		],
	} as Route<typeof TransferVehiclePaths>,
];

export default TransferVehicleRoutes;
