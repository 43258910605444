enum AccountRoles {
	CarrierAdmin = 'carrierAdmin',
	CarrierUser = 'carrierUser',
	JurisdictionAccounting = 'accounting',
	JurisdictionAdmin = 'admin',
	JurisdictionAuditor = 'auditor',
	JurisdictionAuditorAdmin = 'auditorAdmin',
	JurisdictionClerk = 'clerk',
	JurisdictionSupervisor = 'supervisor',
	JurisdictionViewOnly = 'viewOnly',
	ServiceProviderAdmin = 'serviceProviderAdmin',
	ServiceProviderUser = 'serviceProviderUser',
}

export default AccountRoles;
