import { Loader } from 'core/components';
import CoreError from 'core/components/Error';
import { useAPI } from 'core/hooks';
import UserService from 'modules/admin/modules/users/api/userService';
import User from 'modules/admin/types/User';
import { PropsWithChildren, createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'react-oidc-context';

// TODO: This needs some love...
type CurrentUserContextProps = {
	currentUser: User;
};

const CurrentUserContext = createContext<CurrentUserContextProps | undefined>(undefined);

export function CurrentUserProvider({ children }: PropsWithChildren) {
	const userService = useAPI(UserService);
	const { isAuthenticated } = useAuth();
	const { t } = useTranslation();

	const [currentUser, setCurrentUser] = useState<User>();
	const [error, setError] = useState<boolean>(false);

	useEffect(() => {
		if (!isAuthenticated || !!currentUser) return;

		userService
			.me()
			.then((response) => {
				setCurrentUser(response.user);
			})
			.catch(() => {
				setError(true);
			});
	}, [isAuthenticated, currentUser, userService]);

	const value = useMemo<CurrentUserContextProps | undefined>(() => {
		if (!currentUser) return undefined;
		return { currentUser };
	}, [currentUser]);

	if (error) {
		return (
			<CoreError title={t('errors.500.title', { ns: 'core' })} messages={[t('errors.500.message', { ns: 'core' })]} />
		);
	}

	if (!currentUser) {
		return <Loader fullScreen />;
	}

	return <CurrentUserContext.Provider value={value}>{children}</CurrentUserContext.Provider>;
}

export function useCurrentUser() {
	const context = useContext(CurrentUserContext);
	if (context === undefined) {
		throw new Error('useCurrentUser must be used within a CurrentUserProvider');
	}

	return context.currentUser;
}
