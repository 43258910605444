import { Box, Typography } from '@mui/material';
import axios from 'axios';
import { useAPI, useToast } from 'core/hooks';
import DocumentUploader, { DocumentUploaderProps } from 'modules/documents/components/DocumentUploader';
import DocumentsService from 'modules/documents/services/DocumentsService';
import Document, { DocumentType } from 'modules/documents/types/Document';
import SupplementsService from 'modules/irp/modules/supplements/api/SupplementsService';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export interface SupplementDocumentsUploaderProps {
	supplementKey: string;
	documentTypes: DocumentType[];
}

export default function SupplementDocumentsUploader({
	supplementKey,
	documentTypes,
}: SupplementDocumentsUploaderProps) {
	const { t } = useTranslation('documents');
	const supplementsService = useAPI(SupplementsService);
	const documentsService = useAPI(DocumentsService);
	const { openToast } = useToast();

	// State
	const [documents, setDocuments] = useState<Document[]>([]);

	// Load documents from supplement
	const reload = useCallback(() => {
		supplementsService.listDocuments(supplementKey).then(setDocuments);
	}, [supplementKey, supplementsService]);

	// Delete document and reload all documents from supplement
	const onDelete: DocumentUploaderProps['onDelete'] = async ({ id }) => {
		return documentsService
			.deleteDocument(id)
			.then(reload)
			.then(() => {
				openToast({
					id: `delete-document-dialog:${id}`,
					message: t('toasts.deleted'),
					severity: 'success',
				});
			});
	};

	// CLEAR-1909: Upload supplement document
	const uploadFile: DocumentUploaderProps['uploadFile'] = async (file: File, documentType, { setId, setProgress }) => {
		// Get the signed URL and metadata
		return supplementsService.uploadDocument(supplementKey, documentType, file).then((req) => {
			// Set the document ID from response
			setId(req.id);

			// PUT the file on S3
			return axios.put(req.url, file, {
				headers: {
					'Content-Type': file.type,
					...req.metadata,
				},
				// Track progress
				onUploadProgress: (progressEvent) => {
					setProgress(Math.round((progressEvent.loaded / file.size) * 100));
				},
			});
		});
	};

	// CLEAR-2068: Load documents attached to the supplement
	useEffect(() => {
		reload();
	}, [reload]);

	return (
		<Box display="flex" flexDirection="column" rowGap={2}>
			<Typography variant="h4" my={2}>
				{t('upload.title')}
			</Typography>

			<DocumentUploader
				documentTypes={documentTypes}
				documents={documents}
				onDelete={onDelete}
				uploadFile={uploadFile}
			/>
		</Box>
	);
}
