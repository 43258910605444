import { Box, Checkbox, Divider, styled } from '@mui/material';
import { Permission } from 'modules/admin/modules/users/api';
import { CollapsibleContent } from 'modules/iteris-ui/components/CollapsibleContent';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface PermissionSectionProps {
	title: string;
	permissions: Permission[];
	onPermissionChanged: (permission: Permission) => void;
}

const Row = styled(Box)(({ theme }) => ({
	display: 'flex',
	flexDirection: 'row',
	columnGap: theme.spacing(2),
	padding: theme.spacing(2),
	paddingLeft: theme.spacing(10),
}));

const CheckboxColumn = styled(Box)({ display: 'flex', justifyContent: 'center', flex: '20%' });

export default function PermissionSection({ title, permissions, onPermissionChanged }: PermissionSectionProps) {
	const { t } = useTranslation('admin/users');
	const [currentPermissions, setCurrentPermissions] = useState<Permission[]>(permissions);

	const handlePermissionChanged = (updatedPermission: Permission) => {
		setCurrentPermissions(
			currentPermissions.map((permission) => (permission.id === updatedPermission.id ? updatedPermission : permission)),
		);
		onPermissionChanged(updatedPermission);
	};

	return (
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		<CollapsibleContent title={t(`permissions.legacy.${title}`)}>
			<Box display="flex" flexDirection="column" width="50%">
				<Row>
					<Box flex="60%">
						<b>{t('permissions.legacy.permission')}</b>
					</Box>
					<CheckboxColumn>
						<b>{t('permissions.legacy.full_access')}</b>
					</CheckboxColumn>
					<CheckboxColumn>
						<b>{t('permissions.legacy.view_only')}</b>
					</CheckboxColumn>
				</Row>
				<Divider />
				{currentPermissions.map((permission) => (
					<Row key={permission.id}>
						<Box display="flex" alignItems="center" flex="60%">
							{permission.name}
						</Box>
						<CheckboxColumn>
							<Checkbox
								checked={permission.fullAccess}
								onChange={(e) => handlePermissionChanged({ ...permission, fullAccess: e.target.checked })}
							/>
						</CheckboxColumn>
						<CheckboxColumn>
							{!permission.hideView && (
								<Checkbox
									checked={permission.viewOnly}
									onChange={(e) => handlePermissionChanged({ ...permission, viewOnly: e.target.checked })}
								/>
							)}
						</CheckboxColumn>
					</Row>
				))}
				<Divider />
			</Box>
		</CollapsibleContent>
	);
}
