import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { IconButton, styled } from '@mui/material';
import { PropsWithChildren, MouseEvent as ReactMouseEvent, useState } from 'react';

export interface CollapsibleContentProps {
	/** The title for the component */
	title: string;

	/** Controls if the content should be initially expanded */
	expanded?: boolean;

	/** Callback when content is expanded */
	onExpand?: (isExpanded: boolean, e?: ReactMouseEvent) => void;
}

const ContentWrapper = styled('div')(({ theme }) => {
	return {
		display: 'flex',
		alignItems: 'center',
		paddingLeft: theme.spacing(8),
	};
});

const TitleBarWrapper = styled('div')(() => {
	return {
		display: 'flex',
		alignItems: 'center',
	};
});

const TitleWrapper = styled('div')(() => {
	return {
		cursor: 'pointer',
		display: 'flex',
		alignItems: 'center',
	};
});

export function CollapsibleContent({
	children,
	expanded = false,
	title,
	onExpand,
}: PropsWithChildren<CollapsibleContentProps>) {
	const [isExpanded, setIsExpanded] = useState(expanded);

	const handleClick = (e: ReactMouseEvent) => {
		if (onExpand) {
			onExpand(!isExpanded, e);
		}

		setIsExpanded(!isExpanded);
	};

	return (
		<>
			<TitleBarWrapper>
				<IconButton onClick={(e) => handleClick(e)}>
					{isExpanded ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
				</IconButton>
				<TitleWrapper onClick={(e) => handleClick(e)}>{title}</TitleWrapper>
			</TitleBarWrapper>
			{isExpanded ? <ContentWrapper>{children}</ContentWrapper> : null}
		</>
	);
}
