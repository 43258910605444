import { CanAccess } from 'core/components';
import NoPermission from 'core/components/NoPermission';
import Redirect from 'core/components/Redirect';
import Title from 'core/components/Title';
import { Actions } from 'core/types/permissions';
import { Route } from 'core/types/routing';
import UsersRoutes from 'modules/admin/modules/users/routes';
import UserPaths from 'modules/admin/modules/users/routes/paths';
import { Outlet } from 'react-router-dom';
import Permissions from 'types/Permissions';
import AdminPaths from './paths';

// Route component mappings
const AdminRoutes = [
	{
		path: AdminPaths.path,
		element: (
			<CanAccess resource={Permissions.Admin.resource} action={Actions.READ} denied={<NoPermission />}>
				<Title title={(t) => `${t('title', { ns: 'admin' })} - ${t('title', { ns: 'core' })}`} ns={['admin']} />
				<Outlet />
			</CanAccess>
		),
		handle: {
			crumb: ({ t }) => t('title', { ns: 'admin' }),
		},
		children: [
			{
				path: UserPaths.path,
				index: true,
				element: <Redirect to="/" replace />,
			},

			// User routes
			...UsersRoutes,
		],
	} as Route<typeof AdminPaths>,
];

export default AdminRoutes;
