import { DateFormat, dayjsFromObject } from 'core/services/intl';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { Namespace, TFunction } from 'i18next';
import * as Yup from 'yup';

dayjs.extend(isSameOrAfter);
dayjs.extend(isBetween);

export default interface Date {
	year: number;
	month: number;
	day: number;
}

export interface DateRange {
	endDate: Date;
	startDate: Date;
}

export const DateValidationSchema = Yup.object().shape({
	year: Yup.number().defined(),
	month: Yup.number().defined(),
	day: Yup.number().defined(),
});

export const MIN_DATE = dayjs(new Date(2000, 0, 1));
export const MAX_DATE = dayjs(new Date(new Date().getFullYear() + 1, 0, 1));

export const DateValidations = {
	noFutureDate:
		(t: TFunction<Namespace>) =>
		(data: Date | undefined, { createError, path }: Yup.TestContext) => {
			const d = dayjsFromObject(data);
			if (d && d.isAfter(dayjs())) {
				return createError({ path, message: t(`data.validation.datepicker.disableFuture`, { ns: 'core' }) });
			}
			return true;
		},

	// future dates valid when registration period has not started
	checkFutureDate:
		(t: TFunction<Namespace>, futureRegYear: boolean) =>
		(data: Date | undefined, { createError, path }: Yup.TestContext) => {
			if (futureRegYear) return true;
			const d = dayjsFromObject(data);
			if (d && d.isAfter(dayjs())) {
				return createError({ path, message: t(`data.validation.datepicker.disableFuture`, { ns: 'core' }) });
			}
			return true;
		},

	withinRegPeriod:
		(t: TFunction<Namespace>, regYear: DateRange | null) =>
		(data: Date | undefined, { createError, path }: Yup.TestContext) => {
			if (!regYear) return true;
			const date = dayjsFromObject(data);
			const start = dayjsFromObject(regYear.startDate);
			const end = dayjsFromObject(regYear.endDate);
			// don't validate until dates are assigned
			if (!date || !start || !end) return true;
			if (!date.isBetween(dayjsFromObject(regYear.startDate), dayjsFromObject(regYear.endDate), 'day', '[]')) {
				return createError({ path, message: t(`data.validation.datepicker.regYear`, { ns: 'core' }) });
			}
			return true;
		},

	minDate:
		(t: TFunction<Namespace>, minDate?: dayjs.Dayjs) =>
		(data: Date | undefined, { createError, path }: Yup.TestContext) => {
			const d = dayjsFromObject(data);
			return d && d.isBefore(minDate || MIN_DATE)
				? createError({
						path,
						message: t(`data.validation.datepicker.minDate`, {
							ns: 'core',
							minDate: (minDate || MIN_DATE).format(DateFormat),
						}),
					})
				: true;
		},
};

export const dayjsToDate = (date?: dayjs.Dayjs): Date | null => {
	if (!date || !date.isValid()) return null;

	return {
		year: date.year(),
		month: date.month() + 1,
		day: date.date(),
	};
};

export const isOnOrAfter = (d1: dayjs.Dayjs, d2: dayjs.Dayjs) => {
	return d2.isSameOrAfter(d1, 'day');
};
